import React from "react";

import logo from "../../assets/logo.svg";
import whitearrow from "../../assets/white-arrow.svg";
import downarrow from "../../assets/down-arrow.svg";
import headphones from "../../assets/headphones.svg";
import { Link } from "react-router-dom";
import { Mixpanel } from "../../helpers/mixpanel";

const { useState } = React;

function Nav() {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    Mixpanel.track("CLICKED TOGGLE NAV");
    setOpen((value) => !value);
  };

  return (
    <nav className="absolute z-40 w-full top-0 bg-transparent px-12 lg:px-20 xl:px-44 py-5 text-white">
      <div className={"flex justify-between lg:justify-center items-center"}>
        <div className="w-6/12 flex lg:justify-center items-center lg:order-2">
          <img src={logo} alt="logo" className="w-8/12 sm:w-4/12 lg:w-3/12" />
        </div>
        <div
          className={
            "flex flex-col lg:flex-row lg:w-3/12 lg:space-x-7 justify-end lg:items-center uppercase text-xs xl:text-sm order-1 absolute lg:static right-12 top-28 " +
            (!open ? "hidden lg:flex" : "flex ")
          }
        >
          <a className="mt-3 lg:mt-0" href="tel:855-342-3737">
            <button
              className="cta cta-hover bg-transparent"
              onClick={() => Mixpanel.track("CLICKED CALL SUPPORT")}
            >
              <img alt="arrow" src={headphones} className="mr-2" /> +1
              855-342-3737
            </button>
          </a>
        </div>
        <Link
          to="/app/pick-mechanism"
          className={
            "lg:w-3/12 order-3 absolute lg:static right-12 top-16  " +
            (!open ? "hidden lg:block" : "block")
          }
        >
          <button
            className="cta cta-hover uppercase text-xs xl:text-sm"
            onClick={() => Mixpanel.track("CLICKED GET FREE QUOTE")}
          >
            get free quote <img alt="arrow" src={whitearrow} className=" " />
          </button>
        </Link>
        <button
          onClick={toggleOpen}
          className="border-none p-0  block lg:hidden"
        >
          <img
            alt="menu"
            src={downarrow}
            className={
              "transform transition duration-75 ease-in-out h-5 m-0 " +
              (open ? "rotate-180" : "rotate-0")
            }
          />
        </button>
      </div>
    </nav>
  );
}

export default Nav;
