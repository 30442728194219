import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SearchContext } from "../../store";
import "./AltModal.scss";

function AltModal() {
  const history = useHistory();
  const [email, setEmail] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  const [searchMechanism] = useContext(SearchContext);

  const sendDetails = () => {
    const payload = {
      addresses: searchMechanism.addresses,
      service: searchMechanism.service,
      email: email.email,
      mechanism: searchMechanism.mechanism,
    };

    // Simple POST request with a JSON body using fetch
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };

    setLoading(true);

    fetch("https://validator.fiberfed.net/quote/error/", requestOptions)
      .then((response) => {
        response.json();
      })
      .then((data) => {
        toast.success("Your request has been sent", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
        setTimeout(backToHome, 5000);
      })
      .catch((error) => {
        toast.error(error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      });
  };

  const backToHome = () => {
    history.push("/");
  };

  return (
    <div className="py-4">
      <h1 className="text-3xl text-center font-medium">☹️ Oops..</h1>
      <p className="mt-2 text-lightgray sub-text text-center mx-auto w-10/12">
        We just encountered an error processing your request. Please fill in
        your email and our agent would contact you within 24 hours
      </p>
      <div className="my-5 text-xs text-lightgray">
        <p>
          <span className="font-medium">Service: </span>
          {searchMechanism.service}
        </p>
        <p className="mt-2">
          <span className="font-medium">Addresses: </span>
          {searchMechanism.addresses
            .map((address) => address.address)
            .join("\n")}
        </p>
      </div>
      <input
        type="email"
        placeholder="Email address"
        onChange={(event) => setEmail({ ...email, email: event.target.value })}
        className="inapp-input"
      />
      <div className="mt-4 flex justify-center">
        <button
          className="mt-5 text-xs xl:text-sm rounded-full px-6 py-3 font-semibold whitespace-nowrap border flex items-center justify-center border-blue text-white bg-blue"
          onClick={() => sendDetails()}
        >
          {!loading ? (
            "SEND"
          ) : (
            <div className="alt-rect space-x-1">
              <div className="rect1"></div>
              <div className="rect2"></div>
              <div className="rect3"></div>
              <div className="rect4"></div>
              <div className="rect5"></div>
            </div>
          )}
        </button>
      </div>
    </div>
  );
}

export default AltModal;
