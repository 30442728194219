import { Link } from "react-router-dom";
import "./styles.scss";

import LeftArrowButton from "../small-components/LeftArrowButton";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import grayheadphones from "../../assets/grayheadphones.svg";
import whitearrow from "../../assets/white-arrow.svg";
import buildings from "../../assets/buildings.svg";

import { useContext, useEffect, useState } from "react";
import { SearchContext } from "../../store";
import { useHistory } from "react-router-dom";

import Modal from "react-modal";

import buildingtag from "../../assets/buildingtag.svg";

import AltModal from "../AltModal";
import { requestQuotes } from "../../store/slices/quotesSlice";
import { useDispatch, useSelector } from "react-redux";
import { defaultModal, fillAllFields } from "../../helpers";
import { Mixpanel } from "../../helpers/mixpanel";

function RequestQuote() {
  let history = useHistory();
  const dispatch = useDispatch();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [searchMechanism] = useContext(SearchContext);
  const [term, setTerm] = useState(2);

  const { quoteRequest, failedRequestingQuotes, loading } = useSelector(
    (state) => state.quotes
  );

  const sendRequest = () => {
    const email = document.getElementById("email");
    const full_name = document.getElementById("full_name");
    const phone = document.getElementById("phone");

    const quoteBody = {
      bandwidth: searchMechanism.quote,
      email: email.value,
      phone: phone.value,
      full_name: full_name.value,
      term: term,
      quote_id: searchMechanism.quote_id,
    };

    dispatch(
      requestQuotes({
        requestBody: JSON.stringify(quoteBody),
      })
    );
  };

  useEffect(() => {
    if (failedRequestingQuotes) {
      openModal2();
    }
  }, [failedRequestingQuotes]);

  const handleInput = () => {
    const input = document.querySelectorAll(".input");
    for (var i = 0; i < input.length; i++) {
      if (input[i].value === "" && input[i].required === true) {
        var empty = true;
      }
    }
    if (!term) {
      empty = true;
    }
    if (empty) {
      fillAllFields();
    } else {
      sendRequest();
      openModal();
    }
  };
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal2() {
    setIsOpen2(true);
  }

  function afterOpenModal2() {
    // references are now sync'd and can be accessed.
  }

  function closeModal2() {
    setIsOpen2(false);
  }

  useEffect(() => {
    Mixpanel.track("LANDED ON REQUEST QUOTE");
  }, []);

  return (
    <div>
      <div className="lg:h-screen px-8 sm:px-16 py-10 xl:py-32 overflow-y-auto">
        <div className="flex items-center justify-between">
          <button className="" onClick={history.goBack}>
            <LeftArrowButton />
          </button>
          <a href="tel:855-342-3737">
            <button className="lg:hidden cta2 border-gray text-lightgray bg-transparent">
              <img alt="arrow" src={grayheadphones} /> +1 855-342-3737
            </button>
          </a>
        </div>
        <div className="mt-10 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          <h1 className="mt-3 lg:mt-0 text-4xl font-medium order-2 lg:order-1">
            Request a Quote
          </h1>
        </div>
        {/* <div>
          Enter Address(es)🏡 Select Service and Bandwidth 🎛 Fill in Contact
          Information 📝 Place Order 🛒
          ⏩ 🏡 🎛🧾📄📝☝🏻✍🏻
        </div> */}
        <p className="mt-6 lg:mt-12 pb-4 border-b border-gray text-lightgray text-xs sm:text-sm">
          Please fill in your contact information below
        </p>

        <div
          className="bg-blue2 border-blue border-l-3 border-t-4 px-4 py-3"
          role="alert"
        >
          <div className="flex text-black">
            <div className="py-2">
              <svg
                className="fill-current h-6 w-6 text-teal-500 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div>
              {searchMechanism.quote_body.min_cost === "ICB" ? (
                <p className="mt-2">
                  {" "}
                  Fill up the form and our team will get back to you within 24
                  hours
                </p>
              ) : (
                <p className="mt-2">
                  {" "}
                  Installation takes from{" "}
                  <b>{searchMechanism.quote_body.install_interval}</b> Days with
                  one time installation fee of{" "}
                  <b>${searchMechanism.quote_body.nrc}</b>{" "}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="mt-10 flex justify-between items-center text-lightgray">
          <h1 className="text-sm">
            IP Services <br />
            <span className="font-medium text-lg">
              {searchMechanism.service}
            </span>
          </h1>
          <span className="px-3 py-2 bg-fc text-lightgray rounded-full ">
            {searchMechanism.quote}MB
          </span>
        </div>

        <div className="flex flex-wrap justify-between">
          <div className="mt-12 w-full sm:w-5/12">
            <div className="flex">
              <div className="flex items-center px-3 py-2 bg-fc text-lightgray text-sm rounded-full">
                <img alt="building" src={buildings} className="mr-3" />
                Full Name <span className="text-red-600 font-bold pl-1">*</span>
              </div>
            </div>
            <input
              type="text"
              placeholder="First and Last Name"
              className="inapp-input input"
              id="full_name"
              required={true}
            />
          </div>
          <div className="mt-12 w-full sm:w-5/12">
            <div className="flex">
              <div className=" flex items-center w-auto px-3 py-2 bg-fc text-lightgray text-sm rounded-full">
                <img alt="building" src={buildings} className="mr-3" />
                Company Name{" "}
                <span className="text-red-600 font-bold pl-1">*</span>
              </div>
            </div>
            <input
              type="text"
              placeholder="Company Name"
              className="inapp-input input"
              required={true}
            />
          </div>
          <div className="mt-12 w-full sm:w-5/12">
            <div className="flex">
              <div className=" flex items-center w-auto px-3 py-2 bg-fc text-lightgray text-sm rounded-full">
                <img alt="building" src={buildings} className="mr-3" />
                Email Address{" "}
                <span className="text-red-600 font-bold pl-1">*</span>
              </div>
            </div>
            <input
              type="email"
              placeholder="example@domain.com"
              className="inapp-input input"
              id="email"
              required={true}
            />
          </div>
          <div className="mt-12 w-full sm:w-5/12">
            <div className="flex">
              <div className=" flex items-center w-auto px-3 py-2 bg-fc text-lightgray text-sm rounded-full">
                <img alt="building" src={buildings} className="mr-3" />
                Phone Number {"   "}{" "}
                <small className="font-light">(optional)</small>
              </div>
            </div>
            <input
              type="text"
              placeholder="+1 999 999 999"
              className="inapp-input input"
              id="phone"
              required={false}
            />
          </div>
        </div>

        <div className="mt-10">
          <form>
            <label className="text-lightgray text-xs sm:text-sm">
              Chose a duration
            </label>
            <div className="mt-4 flex flex-wrap space-x-4">
              <div className="label">
                <input
                  type="radio"
                  name="duration"
                  value="2"
                  defaultChecked
                  onChange={(e) => setTerm(e.currentTarget.value)}
                />
                <span className="text-lightgray text-sm">2 years</span>
              </div>
              <div className="label">
                <input
                  type="radio"
                  name="duration"
                  value="3"
                  onChange={(e) => setTerm(e.currentTarget.value)}
                />
                <span className="text-lightgray text-sm">3 years</span>
              </div>
              <div className="label">
                <input
                  type="radio"
                  name="duration"
                  value="5"
                  onChange={(e) => setTerm(e.currentTarget.value)}
                />
                <span className="text-lightgray text-sm">5 years</span>
              </div>
            </div>
          </form>
          <p className="mt-3">
            <input type="checkbox" defaultChecked /> Do not process or share my
            information with any 3rd party
          </p>
        </div>
        <div className="">
          <div className="mt-10 flex justify-end">
            <button
              className="cta2 w-full sm:w-auto justify-center border-blue text-white bg-blue"
              onClick={handleInput}
            >
              <img alt="arrow" src={whitearrow} /> GET QUOTE
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={defaultModal}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        {loading ? (
          <>
            <div className="flex items-center justify-center h-full px-16 py-6">
              <div className="flex items-center justify-center">
                <div className="rect space-x-1">
                  <div className="rect1"></div>
                  <div className="rect2"></div>
                  <div className="rect3"></div>
                  <div className="rect4"></div>
                  <div className="rect5"></div>
                </div>
                <p className="ml-2 text-blue font-semibold">
                  Generating Quotes..
                </p>
              </div>
            </div>
          </>
        ) : quoteRequest ? (
          <div>
            <div className="px-5 py-2 flex justify-between items-center pb-3 border-b border-lightergray">
              <h2 className="font-semibold">Quote Sent</h2>
              <button
                onClick={closeModal}
                className="font-bold text-lg text-lightgray"
              >
                x
              </button>
            </div>
            <div className="mt-8 px-8 flex flex-col sm:flex-row justify-between">
              <div className="sm:w-6/12 flex flex-col justify-between">
                <div>
                  <p className="uppercase font-bold text-blue text-sm">
                    quote id: {searchMechanism.quote_id}
                  </p>
                  <p className="mt-10 text-lightgray text-sm">
                    {quoteRequest && quoteRequest.message}
                  </p>
                </div>
                {quoteRequest && quoteRequest.selected_price !== "ICB" ? (
                  <a
                    href={quoteRequest && quoteRequest.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="mt-3 cta2 w-full justify-center border-blue text-white bg-blue">
                      <img alt="arrow" src={whitearrow} /> VIEW QUOTE
                    </button>
                  </a>
                ) : (
                  <></>
                )}
              </div>
              <div className="mt-10 sm:mt-0 sm:w-5/12">
                <div className="mt-4 border border-gray rounded-xl">
                  <div className="px-4 py-6 flex items-center justify-between">
                    <div className="flex space-x-3">
                      <img src={buildingtag} alt="buildingtag" className="" />
                      <div className="text-lightgray text-xs">
                        <p>IP Services</p>
                        <p className="text-sm font-semibold uppercase">
                          {searchMechanism.service}
                        </p>
                      </div>
                    </div>
                    <span className="px-3 py-2 bg-fc text-lightgray text-sm rounded-full order-1 lg:order-2">
                      {searchMechanism.quote}MB
                    </span>
                  </div>
                  <div className="py-3 text-center text-blue bg-quote bg-cover">
                    ${quoteRequest && quoteRequest.selected_price}
                  </div>
                </div>
                <div className="mt-16">
                  <p className="text-lightgray text-xs sm:text-sm">
                    Want another quote?
                  </p>
                  <Link to="/">
                    <button className="mt-3 cta2 w-full justify-center border-blue text-white bg-blue">
                      <img alt="arrow" src={whitearrow} /> GET ANOTHER QUOTE
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {" "}
            <div className="flex items-center justify-center h-full px-16 py-6">
              <p className="text-center">
                Something went wrong, please try again.
              </p>
            </div>
          </>
        )}
      </Modal>
      <Modal
        isOpen={modalIsOpen2}
        onAfterOpen={afterOpenModal2}
        onRequestClose={closeModal2}
        style={defaultModal}
        ariaHideApp={false}
      >
        <AltModal />
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default RequestQuote;
