import React from "react";

import closeicon from "../../assets/white-close.svg";
import whitearrow from "../../assets/white-arrow.svg";
import { Link } from "react-router-dom";

function DiscountModal({ close }) {
  return (
    <div>
      <div className="bg-blue3 py-7 text-center flex items-center space-x-3 justify-center relative">
        <p className="text-f2 text-xl sm:text-3xl font-medium">
          Discount Offer: <br className="sm:hidden" /> 5 Months Free 🎉
        </p>
        <div className="absolute h-full flex items-center right-5 top-0">
          <button
            className="bg-transparent rounded-full border border-f2 h-10 w-10 flex items-center justify-center"
            onClick={() => close()}
          >
            <img src={closeicon} alt="close" className="h-4" />
          </button>
        </div>
      </div>

      <div className="px-5 sm:px-16 bg-white">
        <div className="py-10">
          <div className="text-lightgray text-sm">
            Welcome to Finder by FiberFed! <br /> <br />
            We have special offer in{" "}
            <span className="font-bold">June 2023</span> for a Dedicated
            Internet & MPLS. <br /> <br />
            You qualify for this discount if you: <br />
            <ol className="list-decimal list-inside">
              <li>Run quote and recieve system generated email today</li>
              <li>
                Sign order on or before{" "}
                <span className="font-bold">June 30th</span>.
              </li>
            </ol>
            <br />
            Note: Discounts being offered is 3 months off on 36 term service
            order, and 5 months off for a 60 month term service.
          </div>
        </div>
        <div className="flex flex-col sm:flex-row items-center pb-8 sm:space-x-5">
          <Link
            to="/app/pick-mechanism"
            className=" w-full sm:w-1/2 cta justify-center border-blue text-white bg-blue uppercase"
          >
            GET 5 months Free <img alt="arrow" src={whitearrow} />
          </Link>

          <button
            className="cta w-full sm:w-1/2 justify-center border-blue text-blue bg-white uppercase mt-4 sm:mt-0"
            onClick={() => close()}
          >
            No thanks
          </button>
        </div>
      </div>
    </div>
  );
}

export default DiscountModal;
