import { ReactComponent as Arrow } from "../../assets/gray-arrow.svg";

function RightArrowButton() {
  return (
    <button className="group-hover:bg-orange transition duration-700 rounded-full p-4 flex justify-center border border-gray transform-none">
      <Arrow className="m-0 hoverarrow" />
    </button>
  );
}

export default RightArrowButton;
