import graymotif from "../../assets/graymotif.svg";
import darkarrow from "../../assets/dark-arrow.svg";
import { Link } from "react-router-dom";
import { Mixpanel } from "../../helpers/mixpanel";

function Hero() {
  return (
    <div className="h-screen relative bg-hero bg-cover w-full pt-44 xl:pt-60 text-white">
      <div className="flex flex-col mx-auto justify-center items-center text-center">
        <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold">
          Fast Internet <br />
          for your Business
        </h1>
        <p className="mt-6 lg:mt-8 text-xs lg:text-sm xl:text-base leading-relaxed lg:leading-hero-sub text-blue2">
          We make it fast and easy for businesses to find and order internet
          services.
          <br />
          Click the "GET FREE QUOTE" to start your search
        </p>
        <div className="mt-7 sm:mt-10">
          <Link to="/app/pick-mechanism">
            <button
              className="cta cta-hover uppercase bg-orange border-orange text-dark"
              onClick={() => Mixpanel.track("CLICKED GET QUOTE")}
            >
              get free quote <img alt="arrow" src={darkarrow} />
            </button>
          </Link>
        </div>
      </div>
      <div className="absolute bottom-0 -mb-14 sm:-mb-28 flex w-full justify-center">
        <img src={graymotif} alt="motif" className="" />
      </div>
    </div>
  );
}

export default Hero;
