import { Link, useHistory } from "react-router-dom";

import RightArrowButton from "../small-components/RightArrowButton";
import LeftArrowButton from "../small-components/LeftArrowButton";
import { useContext, useEffect, useState } from "react";
import { SearchContext } from "../../store";

import grayheadphones from "../../assets/grayheadphones.svg";
import Question from "../../assets/question.svg";
import Service from "../../assets/service.svg";
import { Mixpanel } from "../../helpers/mixpanel";
import service from "../../assets/chooseservice.svg";

function PickSearchMechanism() {
  const history = useHistory();

  // eslint-disable-next-line
  const [searchMechanism, setSearchMechanism] = useContext(SearchContext);

  useEffect(() => {
    // Clear local storage at landing
    var items = JSON.parse(localStorage.getItem("searchMechanism"));
    if (items) {
      delete items.budget_min;
      delete items.budget_max;
      delete items.building_type;

      var addresses = items.addresses;

      addresses.splice(0, addresses.length);
      items.mechanism = "";
      items.quote = "";
      items.service = "";

      setState(items);

      localStorage.setItem("searchMechanism", JSON.stringify(items));
    }
  }, []);

  const [state, setState] = useState({
    addresses: [],
    mechanism: "",
    quote: "",
    service: "",
  });

  useEffect(() => {
    Mixpanel.track("LANDED ON PICK MECHANISM SCREEN");
  }, []);

  return (
    <div>
      <div className="lg:h-screen px-8 sm:px-16 py-10 xl:py-32 overflow-y-auto relative">
        <div className=" flex items-center justify-between">
          <button onClick={history.goBack}>
            <LeftArrowButton />
          </button>
          <a href="tel:855-342-3737">
            <button className="lg:hidden cta2 border-gray text-lightgray bg-transparent">
              <img alt="arrow" src={grayheadphones} /> +1 855-342-3737
            </button>
          </a>
        </div>
        <div className="mt-10 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          <h1 className="mt-3 lg:mt-0 text-4xl font-medium order-2 lg:order-1">
            Welcome to FIBERFED Quoting Tool
          </h1>
          <span className="px-3 py-2 bg-fc text-lightgray text-sm rounded-full order-1 lg:order-2">
            Step 1 of 4
          </span>
        </div>
        <p className="mt-6 lg:mt-10 text-dark sm:text-lg">
          We offer 2 ways to get a service quote
        </p>
        <p className="mt-2 pb-4 border-b border-gray text-lightgray text-xs sm:text-sm">
          How do you want to search for a service quote?
        </p>
        <div className="mt-16 space-y-8">
          <Link
            className="group block"
            to="/app/srs"
            onClick={() =>
              setSearchMechanism({
                ...state,
                mechanism: "recommendation",
              })
            }
          >
            <div className="flex justify-between items-center my-4">
              <div className="space-x-4 flex items-center w-9/12">
                <img alt="Question" src={Question} className="h-10 w-10" />
                <div>
                  <h1 className="text-dark font-medium group-hover:text-orange">
                    Help Me Choose
                  </h1>
                  <p className="mt-1 text-lightgray text-xs sm:text-sm">
                    Our “Smart recommendation system” does the heavy lifting...
                  </p>
                </div>
              </div>
              <RightArrowButton />
            </div>
          </Link>

          <Link
            className="group block"
            to="/app/pick-service"
            onClick={() => {
              setSearchMechanism({
                ...state,
                mechanism: "servicetype",
              });
            }}
          >
            <div className="flex justify-between items-center my-4">
              <div className="space-x-4 flex items-center w-9/12">
                <img alt="Service" src={Service} className="h-10 w-10" />
                <div>
                  <h1 className="text-dark font-medium group-hover:text-orange">
                    Service Type
                  </h1>
                  <p className="mt-1 text-lightgray text-xs sm:text-sm">
                    Explore various service types from Broadband MPLS, DIA etc
                  </p>
                </div>
              </div>
              <RightArrowButton />
            </div>
          </Link>

          <a
            rel="noreferrer"
            target="_blank"
            href="https://fiberfed.com"
            onClick={() => Mixpanel.track("CLICKED ABOUT FIBERFED")}
            className="group block"
          >
            <div className="flex justify-between items-center my-4">
              <div className="space-x-4 flex items-center w-9/12">
                <img alt="Service" src={service} className="h-10 w-10" />
                <div>
                  <h1 className="text-dark font-medium group-hover:text-orange">
                    Need more info on Fiberfed
                  </h1>
                  <p className="mt-1 text-lightgray text-xs sm:text-sm">
                    Checkout our home page
                  </p>
                </div>
              </div>
              <RightArrowButton />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default PickSearchMechanism;
