import { Link } from "react-router-dom";
import whitearrow from "../../assets/white-arrow.svg";
import complete from "../../assets/complete.svg";
import { Mixpanel } from "../../helpers/mixpanel";

function SentQuote({ searchMechanism, close, email }) {
  return (
    <div>
      <div className="px-5 py-2 flex justify-between items-center pb-3 border-b border-lightergray">
        <h2 className="font-semibold">Quote Sent</h2>
        <button onClick={close} className="font-bold text-lg text-lightgray">
          x
        </button>
      </div>
      <div className="mt-8 px-8 flex flex-col justify-between items-center">
        <div>
          <p className="font-bold text-blue text-sm text-center">
            Our Agent would contact you via {email} with a quote price
          </p>
        </div>
        <img src={complete} alt="complete" className="h-28 mt-10 mx-auto" />

        <div className="mt-16">
          <p className="text-lightgray text-xs sm:text-sm text-center">
            Want another quote?
          </p>
          <Link to="/">
            <button
              className="mt-3 cta2 w-full justify-center border-blue text-white bg-blue"
              onClick={() =>
                Mixpanel("Quote sent", { action: "Clicked 'Get another quote" })
              }
            >
              <img alt="arrow" src={whitearrow} /> GET ANOTHER QUOTE
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SentQuote;
