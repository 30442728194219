import "./Footer.scss";
import bluearrow from "../../assets/blue-arrow.svg";
import headphones from "../../assets/headphones.svg";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { SearchContext } from "../../store";
import { Mixpanel } from "../../helpers/mixpanel";

function Footer() {
  const [searchMechanism, setSearchMechanism] = useContext(SearchContext);

  const state = {
    addresses: [],
    mechanism: "",
    quote: "",
    service: "",
  };

  return (
    <div className="bg-blue p-8 sm:px-20 sm:py-16 lg:px-24 xl:px-52 lg:py-32 text-white text-left">
      <div className="flex flex-col lg:flex-row lg:justify-between items-center">
        <div className="w-full flex flex-wrap justify-between lg:w-8/12 xl:w-7/12">
          <div className="">
            <h2 className="opacity-50 xl:text-lg">Check Availability</h2>
            <div className="links">
              <Link
                to="/app/pick-service"
                onClick={() => {
                  Mixpanel.track("CLICKED SEARCH BY SERVICE TYPE");

                  setSearchMechanism({
                    ...state,
                    mechanism: "servicetype",
                  });
                }}
                className="cursor-pointer"
              >
                Search by Service Type
              </Link>

              <Link
                to="/app/srs"
                onClick={() => {
                  Mixpanel.track("CLICKED HELP ME FIND");

                  setSearchMechanism({
                    ...state,
                    mechanism: "recommendation",
                  });
                }}
                className="cursor-pointer"
              >
                Help me find
              </Link>
            </div>
          </div>
          <div className="link-group">
            <h2 className="opacity-50 xl:text-lg">Products</h2>
            <div className="links">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://fiberfed.com/dedicated-internet/#dedicated-internet"
                onClick={() => Mixpanel.track("CLICKED DIA")}
              >
                DIA
              </a>
              <a href="/" onClick={() => Mixpanel.track("CLICKED ELAN")}>
                ELAN
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://fiberfed.com/carrier-ethernet/#ELine-PTP"
                onClick={() => Mixpanel.track("CLICKED ELINE")}
              >
                ELINE
              </a>
              <a href="/">BROADBAND</a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://fiberfed.com/carrier-ethernet/#ELine-PTP"
                onClick={() => Mixpanel.track("CLICKED SDWAN")}
              >
                SDWAN
              </a>
            </div>
          </div>
          <div className="link-group mt-5 sm:mt-0">
            <h2 className="opacity-50 xl:text-lg">Social Media</h2>
            <div className="links">
              <a href="/" onClick={() => Mixpanel.track("CLICKED INSTAGRAM")}>
                Instagram
              </a>
              <a href="/" onClick={() => Mixpanel.track("CLICKED TWITTER")}>
                Twitter
              </a>
              <a href="/" onClick={() => Mixpanel.track("CLICKED LINKEDIN")}>
                LinkedIn
              </a>
            </div>
          </div>
        </div>
        <div className="flex items-center flex-col mt-8 sm:mt-16 lg:mt-0">
          <a href="tel:855-342-3737">
            <button
              className="cta cta-hover bg-transparent"
              onClick={() => Mixpanel.track("CLICKED CALL CUSTOMER CARE")}
            >
              <img alt="arrow" src={headphones} className="mr-2" /> +1
              855-342-3737
            </button>
          </a>
          <Link to="/app/pick-mechanism">
            <button
              className="cta cta-hover uppercase mt-4 bg-white text-blue"
              onClick={() => Mixpanel.track("CLICKED GET FREE QUOTE")}
            >
              get free quote <img alt="arrow" src={bluearrow} />
            </button>
          </Link>
        </div>
      </div>

      <p className="text-xs mt-16">2021 Fiberfed Copyright</p>
    </div>
  );
}

export default Footer;
