import { Link, useHistory } from "react-router-dom";

import RightArrowButton from "../small-components/RightArrowButton";
import LeftArrowButton from "../small-components/LeftArrowButton";
import { useContext, useEffect } from "react";
import { SearchContext } from "../../store";

import grayheadphones from "../../assets/grayheadphones.svg";
import dia from "../../assets/dia.svg";
import eline from "../../assets/e-line.svg";
import elan from "../../assets/elan.svg";

import { Mixpanel } from "../../helpers/mixpanel";

function ServiceSelection() {
  const [searchMechanism, setSearchMechanism] = useContext(SearchContext);

  const history = useHistory();

  function handleAnchorClick(event) {
    Mixpanel.track("CLICKED READ MORE OF SERVICE TYPE");
    event.stopPropagation();
  }

  useEffect(() => {
    if (window.location.pathname.includes("/location")) {
      setSearchMechanism({ ...searchMechanism, mechanism: "location" });
    }
    if (window.location.pathname.includes("/budget")) {
      setSearchMechanism({ ...searchMechanism, mechanism: "budget" });
    }

    // Clear local storage at service-selection
    var items = JSON.parse(localStorage.getItem("searchMechanism"));
    if (items) {
      delete items.budget_min;
      delete items.budget_max;
      delete items.building_type;

      var addresses = items.addresses;
      addresses.splice(0, addresses.length);

      items.mechanism = searchMechanism.mechanism;
      items.quote = "";

      setSearchMechanism(items);

      localStorage.setItem("searchMechanism", JSON.stringify(items));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    Mixpanel.track("LANDED ON SERVICE SELECTION");
  }, []);

  return (
    <div>
      <div className="lg:h-screen px-8 sm:px-16 py-10 xl:py-32 overflow-y-auto">
        <div className=" flex items-center justify-between">
          <button
            onClick={() => {
              Mixpanel.track("CLICKED GO BACK");
              history.goBack();
            }}
          >
            <LeftArrowButton />
          </button>
          <a href="tel:855-342-3737">
            <button className="lg:hidden cta2 border-gray text-lightgray bg-transparent">
              <img alt="arrow" src={grayheadphones} /> +1 855-342-3737
            </button>
          </a>
        </div>

        <div className="mt-10 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          <h1 className="mt-3 lg:mt-0 text-4xl font-medium order-2 lg:order-1 w-9/12">
            Now let's choose what type of network you want
          </h1>
          <span className="px-3 py-2 bg-fc text-lightgray text-sm rounded-full order-1 lg:order-2">
            Step 2 of 4
          </span>
        </div>

        <p className="mt-6 lg:mt-12 pb-4 border-b border-gray text-lightgray text-xs sm:text-sm">
          What service type do you require?
        </p>

        <div className="mt-16 space-y-8">
          <Link
            className="group block"
            to={
              "/app" +
              (searchMechanism.mechanism === "location"
                ? "/location-type"
                : searchMechanism.mechanism === "budget"
                ? "/budget-selection"
                : "/addresses")
            }
            onClick={() => {
              Mixpanel.track("CLICKED SELECT SERVICE TYPE - DIA");
              setSearchMechanism({
                ...searchMechanism,
                addresses: [],
                service: "DIA",
              });
            }}
          >
            <div className="flex justify-between items-center my-4">
              <div className="space-x-4 flex items-center w-9/12">
                <img alt="dia" src={dia} />
                <div>
                  <h1 className="text-dark font-medium group-hover:text-orange">
                    DIA
                  </h1>
                  <p className="mt-1 text-lightgray text-xs sm:text-sm">
                    Dedicated Fiber Internet Access; Symmentrical MPLS Circuits
                    <br></br>{" "}
                    <a
                      style={{ textDecoration: "underline" }}
                      onClick={handleAnchorClick}
                      rel="noreferrer"
                      target="_blank"
                      href="https://fiberfed.com/dedicated-internet/#dedicated-internet"
                    >
                      read more...
                    </a>
                  </p>
                </div>
              </div>
              <RightArrowButton />
            </div>
          </Link>

          <Link
            className="group block"
            to={
              "/app" +
              (searchMechanism.mechanism === "location"
                ? "/location-type"
                : searchMechanism.mechanism === "budget"
                ? "/budget-selection"
                : "/addresses")
            }
            onClick={() => {
              Mixpanel.track("CLICKED SELECT SERVICE TYPE - SDWAN");

              setSearchMechanism({
                ...searchMechanism,
                addresses: [],
                service: "SDWAN",
              });
            }}
          >
            <div className="flex justify-between items-center my-4">
              <div className="space-x-4 flex items-center w-9/12">
                <img alt="dia" src={eline} />
                <div>
                  <h1 className="text-dark font-medium group-hover:text-orange">
                    SDWAN
                  </h1>
                  <p className="mt-1 text-lightgray text-xs sm:text-sm">
                    Managed Overlay Service using DIA, MPLS and/or Broadband
                    <br></br>{" "}
                  </p>
                </div>
              </div>
              <RightArrowButton />
            </div>
          </Link>

          <Link
            className="group block"
            to={
              "/app" +
              (searchMechanism.mechanism === "location"
                ? "/location-type"
                : searchMechanism.mechanism === "budget"
                ? "/budget-selection"
                : "/addresses")
            }
            onClick={() => {
              Mixpanel.track("CLICKED SELECT SERVICE TYPE - MPLS");
              setSearchMechanism({
                ...searchMechanism,
                addresses: [],
                service: "MPLS",
              });
            }}
          >
            <div className="flex justify-between items-center my-4">
              <div className="space-x-4 flex items-center w-9/12">
                <img alt="elan" src={elan} />
                <div>
                  <h1 className="text-dark font-medium group-hover:text-orange">
                    MPLS
                  </h1>
                  <p className="mt-1 text-lightgray text-xs sm:text-sm">
                    Traditional MPLS vis SLA Ethernet Circuits (ELINE, ELAN)
                    <br></br>{" "}
                    <a
                      style={{ textDecoration: "underline" }}
                      onClick={handleAnchorClick}
                      target="_blank"
                      rel="noreferrer"
                      href="https://fiberfed.com/carrier-ethernet/#ELine-PTP"
                    >
                      read more...
                    </a>
                  </p>
                </div>
              </div>
              <RightArrowButton />
            </div>
          </Link>

          <Link
            className="group block"
            to={
              "/app" +
              (searchMechanism.mechanism === "location"
                ? "/location-type"
                : searchMechanism.mechanism === "budget"
                ? "/budget-selection"
                : "/addresses")
            }
            onClick={() => {
              Mixpanel.track("CLICKED SELECT SERVICE TYPE - BROADBAND");
              setSearchMechanism({
                ...searchMechanism,
                addresses: [],
                service: "BROADBAND",
              });
            }}
          >
            <div className="flex justify-between items-center my-4">
              <div className="space-x-4 flex items-center w-9/12">
                <img alt="dia" src={dia} />
                <div>
                  <h1 className="text-dark font-medium group-hover:text-orange">
                    BROADBAND
                  </h1>
                  <p className="mt-1 text-lightgray text-xs sm:text-sm">
                    Business Broadband Internet via GPON Fiber, Cable or Fixed
                    Wireless
                  </p>
                </div>
              </div>
              <RightArrowButton />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ServiceSelection;
