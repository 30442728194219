import LeftArrowButton from "../small-components/LeftArrowButton";

import "./styles.scss";
import srsTracker from "../../assets/srs-tracker.svg";
import grayheadphones from "../../assets/grayheadphones.svg";
import { useHistory } from "react-router-dom";
import { useContext, useEffect } from "react";
import { SearchContext } from "../../store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import giveRecommendation from "./srs";
import whitearrow from "../../assets/white-arrow.svg";
import { Mixpanel } from "../../helpers/mixpanel";

function SRS() {
  const [searchMechanism, setSearchMechanism] = useContext(SearchContext);
  var srs = {};

  const smoothScroll = (index, event) => {
    let section = document.getElementById("question-" + (index + 1));
    section && section.scrollIntoView({ behavior: "smooth", block: "center" });

    srs[index] = event.target.value;
  };
  const history = useHistory();

  const getRecommendation = () => {
    var recommendedservice = giveRecommendation(srs);

    Mixpanel.track("CLICKED GET RECOMMENDATION", {
      value: `${recommendedservice}`,
    });

    if (
      recommendedservice === "SDWAN1" ||
      recommendedservice === "SDWAN2" ||
      recommendedservice === "SDWAN3"
    ) {
      setSearchMechanism({ ...searchMechanism, service: "SDWAN" });

      if (recommendedservice === "SDWAN1") {
        searchMechanism["addresses"][0] = {
          ...searchMechanism["addresses"][0],
          service: "DIA_DIA",
        };
        setSearchMechanism({
          ...searchMechanism,
          service: "SDWAN",
          recommendedservice: "SDWAN1",
          addresses: searchMechanism["addresses"],
        });
      } else if (recommendedservice === "SDWAN2") {
        searchMechanism["addresses"][0] = {
          ...searchMechanism["addresses"][0],
          service: "BROADBAND_BROADBAND",
        };
        setSearchMechanism({
          ...searchMechanism,
          service: "SDWAN",
          recommendedservice: "SDWAN2",
          addresses: searchMechanism["addresses"],
        });
      } else if (recommendedservice === "SDWAN3") {
        searchMechanism["addresses"][0] = {
          ...searchMechanism["addresses"][0],
          service: "DIA_BROADBAND",
        };
        setSearchMechanism({
          ...searchMechanism,
          service: "SDWAN",
          recommendedservice: "SDWAN3",
          addresses: searchMechanism["addresses"],
        });
      }
      if (recommendedservice) {
        var message = "Recommended Service: " + recommendedservice + "!";
      } else {
        message = "No service found. Please tweak answers";
      }
      toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(function () {
        if (recommendedservice) {
          history.push("/app/addresses");
        }
      }, 3000);
    } else {
      if (recommendedservice) {
        message = "Recommended Service: " + recommendedservice + "!";
        setSearchMechanism({ ...searchMechanism, service: recommendedservice });
      } else {
        message = "No service found. Please tweak answers";
      }
      toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(function () {
        if (recommendedservice) {
          history.push("/app/addresses");
        }
      }, 3000);
    }
  };

  useEffect(() => {
    Mixpanel.track("LANDED ON SRS SCREEN");
  }, []);

  return (
    <div>
      <div className="flex flex-col lg:flex-row">
        <div className="lg:h-screen overflow-y-auto px-8 sm:px-16 py-10 xl:py-32">
          <img
            src={srsTracker}
            alt="dummy icon in srs screen"
            className="hidden"
          />
          <div className=" flex items-center justify-between">
            <button
              onClick={() => {
                Mixpanel.track("CLICKED GO BACK");
                history.goBack();
              }}
            >
              <LeftArrowButton />
            </button>

            <a className="" href="tel:855-342-3737">
              <button className="lg:hidden cta2 border-gray text-lightgray bg-transparent">
                <img alt="arrow" src={grayheadphones} /> +1 855-342-3737
              </button>
            </a>
          </div>

          <div className="mt-10 flex flex-col lg:flex-row justify-between items-start lg:items-center">
            <h1 className="mt-3 lg:mt-0 text-4xl font-medium order-2 lg:order-1">
              Next Step
            </h1>
            <span className="px-3 py-2 bg-fc text-lightgray text-sm rounded-full order-1 lg:order-2">
              Step 2 of 4
            </span>
          </div>

          <p className="mt-6 lg:mt-12 pb-4 border-b border-gray text-lightgray text-xs sm:text-sm">
            Tap an option to select it.
          </p>

          <div className="mt-6 lg:mt-3">
            <div>
              <div className="tooltip">
                <label className="text-lightgray font-semibold text-xs sm:text-sm uppercase">
                  HOW MANY SITES{" "}
                  <i className="text-lightergray font-thin fa fa-info-circle"></i>
                </label>
                <div>
                  <small className="tooltiptext text-lightergray font-medium">
                    Here we just need to know will you be needing FiberFed
                    connectivity services at 1 or more locations?
                  </small>
                </div>
              </div>
              <div
                className="flex flex-wrap"
                onChange={smoothScroll.bind(this, 1)}
              >
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="site-number"
                    value="1"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "How many sites?",
                        selection: "1",
                      })
                    }
                  />
                  <div className="text">
                    <span>1</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="site-number"
                    value="2+"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "How many sites?",
                        selection: "2+",
                      })
                    }
                  />
                  <div className="text">
                    <span>2+</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-16" id="question-2">
              <div className="tooltip">
                <label className="text-lightgray font-semibold text-xs sm:text-sm uppercase">
                  DIRECTION oF TRAFFIC?{" "}
                  <i className="text-lightergray fa fa-info-circle"></i>
                </label>
                <div>
                  <small className="tooltiptext text-lightergray font-medium">
                    Here we are looking to understand where you intend to
                    communicate, if unsure, its likely “To Internet Only” if
                    there are other sites you communicate to over a private /
                    secure network, via MPLS or SDWAN, then the Site to Site
                    options become relevant
                  </small>
                </div>
              </div>
              <form
                className="flex flex-wrap"
                onChange={smoothScroll.bind(this, 2)}
              >
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="traffic"
                    value="internet_only"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "Direction of traffic",
                        selection: "To Internet only",
                      })
                    }
                  />
                  <div className="text">
                    <span>To Internet only</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="traffic"
                    value="site_to_site"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "Direction of traffic",
                        selection: "Site to Site only",
                      })
                    }
                  />
                  <div className="text">
                    <span>Site to Site only</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="traffic"
                    value="internet_and_site_to_site"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "Direction of traffic",
                        selection: "To Internet and Site to Site",
                      })
                    }
                  />
                  <div className="text">
                    <span>To Internet and Site to Site</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="traffic"
                    value="direct_connect_to_csp"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "Direction of traffic",
                        selection: "Direct Connect to CSP",
                      })
                    }
                  />
                  <div className="text">
                    <span>Direct Connect to CSP</span>
                  </div>
                </div>
              </form>
            </div>

            <div className="mt-16" id="question-3">
              <div className="tooltip">
                <label className="text-lightgray font-semibold text-xs sm:text-sm uppercase">
                  Edge/Firewall Management{" "}
                  <i className="text-lightergray fa fa-info-circle"></i>
                </label>
                <div>
                  <small className="tooltiptext text-lightergray font-medium">
                    If your company needs to lessen IT load, then we can manage
                    the edge device for SDWAN, MPLS, Dedicated Internet, BGP,
                    then FiberFed Managed is for you, if you have a robust IT
                    team and can handle this, then choose “Self/3rd Party
                    Managed”
                  </small>
                </div>
              </div>
              <div
                className="flex flex-wrap"
                onChange={smoothScroll.bind(this, 3)}
              >
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="firewall"
                    value="self_managed"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "Edge/Firewall Management",
                        selection: "Fiberfed Managed",
                      })
                    }
                  />
                  <div className="text">
                    <span>Fiberfed Managed</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="firewall"
                    value="3rd_party"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "Edge/Firewall Management",
                        selection: "Self/3rd Party Managed",
                      })
                    }
                  />
                  <div className="text">
                    <span>Self/3rd Party Managed</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-16" id="question-4">
              <div className="tooltip">
                <label className="text-lightgray font-semibold text-xs sm:text-sm uppercase">
                  what minimum time to recovery can you bear?{" "}
                  <i className="text-lightergray fa fa-info-circle"></i>
                </label>
                <div>
                  <small className="tooltiptext text-lightergray font-medium">
                    There are many ways to design Infrastructure delivery. These
                    design weigh costs vs uptime and refer to MTTR and MTBF.
                    There are degrees to Dedicated Internet uptime, and degrees
                    to Broadband reliability. We try to take your business
                    operations tolerance for network outage vs budget of telecom
                  </small>
                </div>
              </div>
              <div
                className="flex flex-wrap"
                onChange={smoothScroll.bind(this, 4)}
              >
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="minimum-time"
                    value="0"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "what minimum time to recovery can you bear?",
                        selection: "0 hours",
                      })
                    }
                  />
                  <div className="text">
                    <span>0 hours</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="minimum-time"
                    value="4"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "what minimum time to recovery can you bear?",
                        selection: "4 hours",
                      })
                    }
                  />
                  <div className="text">
                    <span>4 hours</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="minimum-time"
                    value="24"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "what minimum time to recovery can you bear?",
                        selection: "24 hours",
                      })
                    }
                  />
                  <div className="text">
                    <span>24 hours</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="minimum-time"
                    value="48"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "what minimum time to recovery can you bear?",
                        selection: "48 hours",
                      })
                    }
                  />
                  <div className="text">
                    <span>48 hours</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="minimum-time"
                    value="not_sure"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "what minimum time to recovery can you bear?",
                        selection: "not sure",
                      })
                    }
                  />
                  <div className="text">
                    <span>Not Sure</span>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="mt-16" id="question-5">
              <label className="text-lightgray text-xs sm:text-sm uppercase">
                how often can you bear downtimes? <i className="text-lightergray fa fa-info-circle"></i>
              </label>
              <div
                className="flex flex-wrap"
                onChange={smoothScroll.bind(this, 5)}
              >
                <div className="input-wrapper">
                  <input type="radio" name="downtime" value="100_days" />
                  <div className="text">
                    <span>100 days BF</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input type="radio" name="downtime" value="75_days" />
                  <div className="text">
                    <span>75 days BF</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input type="radio" name="downtime" value="50_days" />
                  <div className="text">
                    <span>50 days BF</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input type="radio" name="downtime" value="25_days" />
                  <div className="text">
                    <span>25 days BF</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input type="radio" name="downtime" value="never" />
                  <div className="text">
                    <span>Never</span>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="mt-16" id="question-5">
              <div className="tooltip">
                <label className="text-lightgray font-semibold text-xs sm:text-sm uppercase">
                  how many users/employees would use this internet?{" "}
                  <i className="text-lightergray fa fa-info-circle"></i>
                </label>
                <div>
                  <small className="tooltiptext text-lightergray font-medium">
                    Your company may have 500 employees, but if only 100 are
                    using the internet through the week, we try to moderate the
                    size of the circuits you may need.
                  </small>
                </div>
              </div>
              <div
                className="flex flex-wrap"
                onChange={smoothScroll.bind(this, 5)}
              >
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="users"
                    value="1-5"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question:
                          "how many users/employees would use this internet?",
                        selection: "1 - 5",
                      })
                    }
                  />
                  <div className="text">
                    <span>1 - 5</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="users"
                    value="6-10"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question:
                          "how many users/employees would use this internet?",
                        selection: "6 - 10",
                      })
                    }
                  />
                  <div className="text">
                    <span>6 - 10</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="users"
                    value="11-25"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question:
                          "how many users/employees would use this internet?",
                        selection: "11 - 25",
                      })
                    }
                  />
                  <div className="text">
                    <span>11 - 25</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="users"
                    value="26-50"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question:
                          "how many users/employees would use this internet?",
                        selection: "26 - 50",
                      })
                    }
                  />
                  <div className="text">
                    <span>26 - 50</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="users"
                    value="51-200"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question:
                          "how many users/employees would use this internet?",
                        selection: "51 - 200",
                      })
                    }
                  />
                  <div className="text">
                    <span>51 - 200</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="users"
                    value="201+"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question:
                          "how many users/employees would use this internet?",
                        selection: "201+",
                      })
                    }
                  />
                  <div className="text">
                    <span>201+</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-16" id="question-6">
              <div className="tooltip">
                <label className="text-lightgray font-semibold text-xs sm:text-sm uppercase">
                  what'll you be using the internet for?{" "}
                  <i className="text-lightergray fa fa-info-circle"></i>
                </label>
                <div>
                  <small className="tooltiptext text-lightergray font-medium">
                    Our Internet is the life blood of communications, and no 2
                    businesses are the same, but these groupings attempt to
                    profile your service usage and allow us to design circuits
                    that meet packet loss, latency and jitter requirements,
                    while also considering upload vs download needs.
                  </small>
                </div>
              </div>

              <div
                className="flex flex-wrap"
                onChange={smoothScroll.bind(this, 6)}
              >
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="use-of-internet"
                    value="video_conference"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "what'll you be using the internet for?",
                        selection:
                          "Video Conferencing, Cloud phone system, Cloud file shares",
                      })
                    }
                  />
                  <div className="text">
                    <span>
                      Video Conferencing, Cloud phone system, Cloud file shares
                    </span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="use-of-internet"
                    value="streaming"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "what'll you be using the internet for?",
                        selection:
                          "Streaming, Web surfing, Email, Credit card machine",
                      })
                    }
                  />
                  <div className="text">
                    <span>
                      Streaming, Web surfing, Email, Credit card machine
                    </span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="use-of-internet"
                    value="hosted_server"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "what'll you be using the internet for?",
                        selection:
                          "Hosted Servers(s), Application, VPN aggregation, OnPremise phone system",
                      })
                    }
                  />
                  <div className="text">
                    <span>
                      Hosted Servers(s), Application, VPN aggregation, OnPremise
                      phone system
                    </span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="use-of-internet"
                    value="gaming"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "what'll you be using the internet for?",
                        selection: "Gaming, Real-time financial trading",
                      })
                    }
                  />
                  <div className="text">
                    <span>Gaming, Real-time financial trading</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="use-of-internet"
                    value="not_sure"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "what'll you be using the internet for?",
                        selection: "Not Sure",
                      })
                    }
                  />
                  <div className="text">
                    <span>Not Sure</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-16" id="question-7">
              <div className="tooltip">
                <label className="text-lightgray font-semibold  text-xs sm:text-sm uppercase">
                  when do you need the services installed?{" "}
                  <i className="text-lightergray fa fa-info-circle"></i>
                </label>
                <div>
                  <small className="tooltiptext text-lightergray font-medium">
                    Depending on the choices above and the location(s) you need
                    service; time is a major factor many don't consider. If we
                    determine you need Dedicated Fiber, and don't have that to
                    your building or don't want to use an existing service in
                    the building, then we need to plan for construction or
                    electronics sourcing. Broadband is typically quicker to
                    install than Dedicated services, but if your install date
                    requirement supersedes circuit design, we need to know.
                  </small>
                </div>
              </div>

              <div
                className="flex flex-wrap"
                onChange={smoothScroll.bind(this, 7)}
              >
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="installation-time"
                    value="2_weeks"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "when do you need the services installed?",
                        selection: "Less than 2 weeks",
                      })
                    }
                  />
                  <div className="text">
                    <span>Less than 2 weeks</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="installation-time"
                    value="1_month"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "when do you need the services installed?",
                        selection: "Less than 1 month",
                      })
                    }
                  />
                  <div className="text">
                    <span>Less than 1 month</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="installation-time"
                    value="2_months"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "when do you need the services installed?",
                        selection: "Less than 2 months",
                      })
                    }
                  />
                  <div className="text">
                    <span>Less than 2 months</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="installation-time"
                    value="3_months"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "when do you need the services installed?",
                        selection: "Less than 3 months",
                      })
                    }
                  />
                  <div className="text">
                    <span>Less than 3 months</span>
                  </div>
                </div>
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="installation-time"
                    value="not_sure"
                    onClick={() =>
                      Mixpanel.track("SELECTED SRS ANSWER", {
                        question: "when do you need the services installed?",
                        selection: "Not sure",
                      })
                    }
                  />
                  <div className="text">
                    <span>Not sure</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10 flex flex-col lg:flex-row justify-end">
            <button
              className="cta2 mt-7 lg:mt-0 w-full sm:w-auto justify-center border-blue text-white bg-blue"
              onClick={getRecommendation}
            >
              <img alt="arrow" src={whitearrow} /> CONTINUE
            </button>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SRS;
