import SRS from "../SRS";
import GetQuote from "../GetQuote";
import Addresses from "../Addresses";
import LeftSidebar from "../LeftSidebar";
import RequestQuote from "../RequestQuote";
import LocationType from "../LocationType";
import { SearchContext } from "../../store";
import BudgetSelection from "../BudgetSelection";
import ServiceSelection from "../ServiceSelection";
import React, { useContext, useEffect } from "react";
import InAppFooter from "../InAppFooter/InAppFooter";
import PickSearchMechanism from "../PickSearchMechanism";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

function MainApp() {
  let { path } = useRouteMatch();

  const [searchMechanism, setSearchMechanism] = useContext(SearchContext);

  useEffect(() => {
    if (
      window.location.pathname.includes("/location") &&
      searchMechanism.mechanism !== "location"
    ) {
      setSearchMechanism((searchMechanism) => ({
        ...searchMechanism,
        mechanism: "location",
      }));
    }
    if (
      window.location.pathname.includes("/budget") &&
      searchMechanism.mechanism !== "budget"
    ) {
      setSearchMechanism((searchMechanism) => ({
        ...searchMechanism,
        mechanism: "budget",
      }));
    }
    // eslint-disable-next-line
  }, [searchMechanism]);

  return (
    <div>
      <div className="flex">
        <LeftSidebar />
        <div className="w-full lg:w-7/12">
          <Switch>
            <Route
              path={`${path}/pick-mechanism`}
              render={(props) => <PickSearchMechanism {...props} />}
            />
            <Route
              path={`${path}/pick-service`}
              render={(props) => <ServiceSelection {...props} />}
            />
            <Route
              path={`${path}/location/pick-service`}
              render={(props) => <ServiceSelection {...props} />}
            />
            <Route
              path={`${path}/budget/pick-service`}
              render={(props) => <ServiceSelection {...props} />}
            />
            <Route
              path={`${path}/location-type`}
              render={(props) => <LocationType {...props} />}
            />
            <Route
              path={`${path}/addresses`}
              render={(props) => <Addresses {...props} />}
            />
            <Route
              path={`${path}/budget-selection`}
              render={(props) => <BudgetSelection {...props} />}
            />
            <Route
              path={`${path}/get-quote`}
              render={(props) => <GetQuote {...props} />}
            />
            <Route
              path={`${path}/request-quote`}
              render={(props) => <RequestQuote {...props} />}
            />
            <Route
              path={`${path}/srs`}
              render={(props) => <SRS {...props} />}
            />
            <Redirect to={`${path}/pick-mechanism`} />
          </Switch>
        </div>
      </div>

      <InAppFooter />
    </div>
  );
}

export default MainApp;
