import { Link, useHistory } from "react-router-dom";

import LeftArrowButton from "../small-components/LeftArrowButton";

import grayheadphones from "../../assets/grayheadphones.svg";
import whitearrow from "../../assets/white-arrow.svg";
import tag from "../../assets/locationtag.svg";
import { useContext } from "react";
import { SearchContext } from "../../store";

function LocationType() {
  const history = useHistory();

  const [searchMechanism, setSearchMechanism] = useContext(SearchContext);

  const handleChange = (event) => {
    setSearchMechanism({
      ...searchMechanism,
      building_type: event.target.value,
    });
  };

  return (
    <div>
      <div className="lg:h-screen px-8 sm:px-16 py-10 xl:py-32">
        <div className=" flex items-center justify-between">
          <button onClick={history.goBack}>
            <LeftArrowButton />
          </button>
          <a href="tel:855-342-3737">
            <button className="lg:hidden cta2 border-gray text-lightgray bg-transparent">
              <img alt="arrow" src={grayheadphones} /> +1 855-342-3737
            </button>
          </a>
        </div>
        <div className="mt-10 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          <h1 className="mt-3 lg:mt-0 text-4xl font-medium order-2 lg:order-1">
            Next Step
          </h1>
          <span className="px-3 py-2 bg-fc text-lightgray text-sm rounded-full order-1 lg:order-2">
            Step 3 of 4
          </span>
        </div>
        <p className="mt-6 lg:mt-12 pb-4 border-b border-gray text-lightgray text-xs sm:text-sm">
          What address would you want to install this at?
        </p>
        <div className="">
          <img alt="tag" src={tag} className="mt-16" />
          <select
            className="mt-5 w-full border-b border-orange py-4 text-sm text-lightgray bg-transparent focus:outline-none"
            onChange={handleChange.bind(this)}
          >
            <option disabled selected>
              Select an address type
            </option>
            <option value="data-center">Data Center</option>
            <option value="business-name">Business Name</option>
            <option value="address">Address</option>
          </select>
          <div className="mt-10 flex justify-end">
            <Link to="/app/addresses">
              <button className="cta2 w-full sm:w-auto justify-center border-blue text-white bg-blue">
                <img alt="arrow" src={whitearrow} /> CONTINUE
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationType;
