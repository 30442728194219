import { toast } from "react-toastify";

export const defaultModal = {
  content: {
    borderRadius: "8px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
  },
};

export const requestQuote = {
  content: {
    borderRadius: "8px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
  },
};

export const mobileRequestQuote = {
  content: {
    borderRadius: "8px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export const tiers = {
  tier1: { max: 199, min: 0 },
  tier2: { min: 200, max: 1999 },
  tier3: { min: 2000, max: 9999999 },
};

export const bandwidths = [
  10, 20, 50, 100, 200, 300, 500, 1000, 2000, 3000, 5000, 10000,
];

export const broadbandOptions = ["LOW", "MEDIUM", "HIGH", "EXTRA_HIGH"];

export const fillAllFields = () => {
  return toast.error("Please fill in all fields", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const formatBandwidth = (data, isBroadband) => {
  if (data) {
    if (!isBroadband) {
      if (data === 0) return "0 MB";

      return data >= 1000 ? data / 1000 + "GB" : data + "MB";
    } else if (typeof data === "string") {
      return data?.replace("_", " ");
    }
  }
};
