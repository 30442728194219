import React, { useState } from "react";

import plus from "../../assets/plus.svg";
import { Mixpanel } from "../../helpers/mixpanel";

function FAQ({ faq }) {
  const [checked, setChecked] = useState(false);
  const toggleChecked = () => {
    Mixpanel.track("SELECTED FAQ", {
      FAQ: faq.title,
    });

    setChecked((value) => !value);
  };

  return (
    <div className="mt-4">
      <button
        className="flex items-center space-x-3 border-none transform-none py-3 focus:outline-none w-full px-2 sm:px-5"
        onClick={toggleChecked}
      >
        <img
          src={plus}
          alt="open"
          className={
            "transform transition duration-100 ease-in-out h-3 " +
            (checked ? "rotate-45" : "rotate-0")
          }
        />
        <p className="text-left whitespace-normal font-medium text-dark">
          {faq.title}
        </p>
      </button>
      <div
        className={
          "content overflow-hidden pl-8 sm:pl-10 pr-2 sm:pr-5 " +
          (!checked ? "h-0" : "h-fit open")
        }
      >
        <p className="py-6 w-11/12 text-sm text-lightgray">{faq.content}</p>
      </div>
    </div>
  );
}
export default FAQ;
