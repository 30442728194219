import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import TagManager from "react-gtm-module";
import { rtkStore } from "./store/rtkStore";
import { Provider } from "react-redux";

const tagManagerArgs = {
  gtmId: "GTM-WSW8H2X",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={rtkStore}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
