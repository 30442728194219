const srs_services = {
  1: {
    1: {
      include: [],
      exclude: ["MPLS", "SDWAN1", "SDWAN2", "SDWAN3"],
    },
    "2+": {
      include: [],
      exclude: [],
    },
  },
  2: {
    internet_only: {
      include: [],
      exclude: ["MPLS"],
    },
    site_to_site: {
      include: ["MPLS"],
      exclude: ["BROADBAND", "DIA", "BGPDIA"],
    },
    internet_and_site_to_site: {
      include: [],
      exclude: [],
    },
    direct_connect_to_csp: {
      include: ["DIA", "MPLS"],
      exclude: [],
    },
  },
  3: {
    self_managed: {
      include: ["DIA", "BGPDIA", "SDWAN1", "SDWAN2", "SDWAN3"],
      exclude: [],
    },
    "3rd_party": {
      include: [],
      exclude: ["SDWAN1", "SDWAN2", "SDWAN3"],
    },
  },
  4: {
    0: {
      include: ["BGPDIA", "SDWAN1", "SDWAN3"],
      exclude: ["BROADBAND", "SDWAN2"],
    },
    4: {
      include: ["DIA", "BGPDIA", "SDWAN1", "SDWAN3", "MPLS"],
      exclude: ["BROADBAND"],
    },
    24: {
      include: [],
      exclude: [],
    },
    48: {
      include: ["BROADBAND"],
      exclude: ["BGPDIA"],
    },
    not_sure: {
      include: [],
      exclude: [],
    },
  },
  5: {
    "1-5": {
      include: ["BROADBAND"],
      exclude: [],
    },
    "6-10": {
      include: ["BROADBAND", "SDWAN2"],
      exclude: [],
    },
    "11-25": {
      include: ["DIA", "MPLS", "SDWAN2", "SDWAN3"],
      exclude: [],
    },
    "26-50": {
      include: ["DIA", "SDWAN3", "MPLS"],
      exclude: [],
    },
    "51-200": {
      include: ["BGPDIA", "DIA", "MPLS", "SDWAN1", "SDWAN3"],
      exclude: ["BROADBAND"],
    },
    "201+": {
      include: ["BGPDIA", "DIA", "MPLS", "SDWAN1", "SDWAN3"],
      exclude: ["BROADBAND", "SDWAN2"],
    },
  },
  6: {
    video_conference: {
      include: ["DIA", "SDWAN1", "SDWAN3"],
      exclude: [],
    },
    streaming: {
      include: ["BROADBAND"],
      exclude: [],
    },
    hosted_server: {
      include: ["DIA", "BGPDIA"],
      exclude: ["BROADBAND", "SDWAN2"],
    },
    gaming: {
      include: ["DIA"],
      exclude: [],
    },
    not_sure: {
      include: [],
      exclude: [],
    },
  },
  7: {
    "2_weeks": {
      include: ["BROADBAND"],
      exclude: ["BGPDIA", "MPLS", "SDWAN1"],
    },
    "1_month": {
      include: ["SDWAN2"],
      exclude: ["BGPDIA"],
    },
    "2_months": {
      include: [],
      exclude: [],
    },
    "3_months": {
      include: [],
      exclude: [],
    },
    not_sure: {
      include: [],
      exclude: [],
    },
  },
};
function SimpleMode(arr) {
  var mode = "";
  var count = 0;
  for (var i = 0; i < arr.length; i++) {
    for (var j = 0; j < i; j++) {
      if (arr[j] === arr[i]) {
        mode = arr[j];
        count++;
      }
    }
  }
  return mode;
}

const srs_obj = {
  recommendation_list: [],
  exclusion_list: [],
  removeItem(item) {
    this.recommendation_list = this.recommendation_list.filter(
      (i) => i !== item
    );
    return this;
  },
};

const giveRecommendation = (srs) => {
  for (var key in srs) {
    srs_obj.recommendation_list = srs_obj.recommendation_list.concat(
      srs_services[key][srs[key]]["include"]
    );
    srs_obj.exclusion_list = srs_obj.exclusion_list.concat(
      srs_services[key][srs[key]]["exclude"]
    );
  }
  var recommendation_list = srs_obj.recommendation_list.filter(function (
    value,
    index,
    array
  ) {
    return !srs_obj.exclusion_list.includes(value);
  });
  return SimpleMode(recommendation_list);
};

export default giveRecommendation;
