/* eslint-disable no-multi-str */
import "./Landing.scss";

import Nav from "../Nav/Nav";
import Hero from "../Hero/Hero";
import FAQ from "../FAQ/FAQ";
import Footer from "../Footer/Footer";

import altice from "../../assets/altice.svg";
import century from "../../assets/century.svg";
import coxbusiness from "../../assets/coxbusiness.svg";

import graymotif from "../../assets/graymotif.svg";
import whitemotif from "../../assets/whitemotif.svg";

import globe from "../../assets/globe.svg";
import coverage from "../../assets/coverage.svg";
import prices from "../../assets/prices.svg";
import support from "../../assets/support.svg";
import bandwidth from "../../assets/bandwidth.svg";
import installation from "../../assets/installation.svg";
import darkarrow from "../../assets/dark-arrow.svg";

import DiscountModal from "../DiscountModal";
import React, { useState } from "react";
import Modal from "react-modal/lib/components/Modal";
import { Link } from "react-router-dom";
import { Mixpanel } from "../../helpers/mixpanel";

function Landing() {
  const [modalIsOpen, setIsOpen] = useState(false);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  React.useEffect(() => {
    Mixpanel.track("LANDED ON THE HOME PAGE");
  }, []);

  return (
    <div>
      <div className="relative">
        <Nav />
        <Hero />
        <div className="relative">
          <div className="px-5 sm:px-20 py-24 pb-36 sm:py-32 xl:py-52">
            <h3 className="text-center font-bold sm:w-6/12 xl:w-4/12 mx-auto text-xl sm:text-2xl xl:text-3xl">
              Interconnected with over 100 carriers nationwide
            </h3>
            <div className="clients sm:w-7/12 mx-auto flex justify-between mt-8 lg:mt-16">
              <img src={coxbusiness} alt="cox" />
              <img src={altice} alt="altice" />
              <img src={century} alt="century" />
            </div>
          </div>
          <div className="absolute bottom-0 -mb-32 flex w-full justify-center">
            <img src={graymotif} alt="motif" className="" />
          </div>
        </div>
        {/* <SearchBy /> */}
        <div className="relative">
          <div className="px-5 sm:px-28 xl:px-60 py-24 pb-36 sm:py-32 xl:py-24 xl:pb-44 bg-blue">
            <h3 className="text-center font-bold mx-auto text-xl sm:text-2xl xl:text-3xl text-white">
              Why Fiberfed?
            </h3>
            <div className="features">
              <div className="feature">
                <img src={globe} alt="globe" />
                <div>
                  <h2>Reliable</h2>
                  <p>
                    We have 100% uptime across all fiber services through the
                    country
                  </p>
                </div>
              </div>
              <div className="feature">
                <img src={coverage} alt="coverage" />
                <div>
                  <h2>Wide Coverage</h2>
                  <p>
                    Strategic partnerships with many networks enables us to
                    provide coverage anywhere
                  </p>
                </div>
              </div>
              <div className="feature">
                <img src={prices} alt="prices" />
                <div>
                  <h2>Affordable Prices</h2>
                  <p>Our prices are the most competitive across the country</p>
                </div>
              </div>
              <div className="feature">
                <img src={support} alt="support" />
                <div>
                  <h2>24/7 Customer Support</h2>
                  <p>
                    Always on call to answer your questions and provide support
                  </p>
                </div>
              </div>
              <div className="feature">
                <img src={bandwidth} alt="bandwidth" />
                <div>
                  <h2>High Bandwidth</h2>
                  <p>
                    Up to 10,000MbS upload/download with ultra low latency and
                    jitter.
                  </p>
                </div>
              </div>
              <div className="feature">
                <img src={installation} alt="installation" />
                <div>
                  <h2>Fast Installation</h2>
                  <p>We install fiber in less than 30 days</p>
                </div>
              </div>
            </div>

            <div className="mt-10 flex justify-center">
              <Link to="/app/pick-mechanism">
                <button
                  className="cta cta-hover uppercase bg-orange border-orange text-dark"
                  onClick={() => Mixpanel.track("CLICKED GET QUOTE")}
                >
                  get free quote <img alt="arrow" src={darkarrow} />
                </button>
              </Link>
            </div>
          </div>
          <div className="absolute bottom-0 -mb-32 flex w-full justify-center">
            <img src={whitemotif} alt="motif" className="" />
          </div>
        </div>
        {/* <div className="py-32 xl:py-44 flex flex-col sm:flex-row justify-between w-10/12 lg:w-8/12 xl:w-7/12 mx-auto">
          <h3 className="text-center sm:text-left sm:w-5/12 font-bold text-3xl sm:text-5xl xl:text-6xl">
            What our customers are saying
          </h3>
          <div className="testimonials">
            <p className="text-sm text-lightgray">
              “If Internet access is mission-critical to your organization,
              FiberFed can pair multiple ISPs to create a redundant Internet
              circuit (BGP). This technology is used by banks and financial
              institutions worldwide to create rock-solid stability and increase
              bandwidth”
            </p>
            <div className="mt-6 flex items-center space-x-4">
              <img src={avatar} alt="avatar" className="h-12" />
              <p className="text-sm">Nishant Batra, CTO at Nokia</p>
            </div>
            <div className="flex space-x-4 justify-end">
              <button>
                <img src={leftarrow} alt="arrow" className="m-0" />
              </button>
              <button>
                <img src={grayarrow} alt="arrow" className="m-0" />
              </button>
            </div>
          </div>
        </div> */}
        <div className="relative bg-gray">
          <div className="absolute top-0 -mt-32 flex w-full justify-center">
            <img src={whitemotif} alt="motif" className="" />
          </div>
          <div className="py-32 xl:py-56 w-10/12 lg:w-8/12 xl:w-7/12 mx-auto">
            <h3 className="text-center font-bold text-3xl sm:text-5xl xl:text-6xl">
              FAQs
            </h3>
            <div className="mt-12">
              {faq.map((value, index) => {
                return <FAQ key={index} faq={value} />;
              })}
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        ariaHideApp={false}
        className={`${
          window.screen.width <= 500 ? "w-11/12" : "max-w-650"
        } discount-modal`}
        overlayClassName="discount-overlay"
      >
        <DiscountModal close={closeModal} />
      </Modal>
    </div>
  );
}

export default Landing;

const faq = [
  {
    title: "ATT and Verizon I have heard of, Who is FiberFed?",
    content:
      "FiberFed is a Competitive alternative to Local Telephone and Cable Companys. We leverage the best part of those companies \
      and provide better pricing and better customer service. We have Wholesale contracts with almost every Fiber Network in the US. \
      We leverage our technology to find the best network, at the best price, with the quickest install. If you want us to exclude a \
      particular carrier from the solution we provide you, we will! We have POPs in DataCenters throughout the country and provide our Internet and Network Services from there.",
  },
  {
    title: "Why FiberFed?",
    content:
      "FiberFed uses its proprietary database algorithms for cost savings and order efficiencies. Since we have our nationwide \
      Fiber network to leverage, we are able to get the best and quickest communications solutions that can be found in the \
      Telecom market today. Try us and if you don't agree, you can cancel your order!",
  },
  {
    title: "How does the Fiber Finder work?",
    content:
      "We have provided 4 searching methods based on your expertise and/or your goals. We have a tool that helps you chose a service. \
      You can shop based on Location, seeing what services are at the building. You can shop based on a specific Service Type, or you \
      can shop based on budget. Once you chose you search method, and enter address, we provide a popup sharing some results. In order to get a more specific quote, \
      just enter your email and name. We will immediately send pricing, installation costs, and even install interval!\
      If you like what you see, we make it easy to start your order and then provide updates to your order as it hits stages of completion!",
  },
  {
    title: "What Services can I buy through FiberFed?",
    content:
      "As our name suggests, we specialize in Fiber! We mostly sell Dedicated Internet and MPLS private services. We sell Wave services. We also can provide Dedicated services via \
      licensed Microwave for hard to reach areas or for diversity requirements. Broadband is another service we can provide.\
      Recently we also started selling our SDWAN solution that comes bundled as a service. Lastly, we'd like to highlight that \
      we can get you a Direct connection to the Cloud Services Providers and other DataCenters.",
  },
  {
    title: "Where can you service?",
    content:
      "Most of the US. If there is electricity, there is a good chance we can provide a Connectivity service.",
  },
  {
    title: "I have no idea how to order Telecom, what is my first step?",
    content:
      "We created SRS, our Smart Recommendation Service, to make chosing a connectivity service easier. Try it now! https://finder.fiberfed.com/srs",
  },
  {
    title: "I am a Telecom guru, how do I know I am getting the best solution?",
    content:
      "In addition to SRS, there are 3 other searching methods to chose from. As long as you have the address(es), we can get you \
      a pricing and availability within seconds.Once we send our solution, you can instant chat with one of our Sales Engineers or \
      you can setup a time to discuss further.",
  },
  {
    title: "Do you sell my info?",
    content:
      "No. You are our client, and we are your trusted advisor. We may contact to share about updates to our offering or pricing promotions, but your info is safe with us.",
  },
  {
    title: "What if I order, and then want to cancel?",
    content:
      "Things happen, and sometimes you have to cancel. We allow for canceling to happen with no charge for 5 \
      business days after order. Depending on the complexity of the order, you may have more time, however after we \
      establish a firm Install date, thats when the legalize kicks in and some fees may apply.",
  },
];
