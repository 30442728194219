import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  quoteList: null,
  quoteRequest: null,
  loading: false,
  requestLoading: false,
  requestFailed: false,
  failedRequestingQuotes: false,
  ipAddress: false,
};

export const quotesSlice = createSlice({
  name: "quotes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getQuotes.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getQuotes.fulfilled, (state, action) => {
      state.loading = false;
      state.quoteList = action.payload;
    });
    builder.addCase(getQuotes.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(requestQuotes.pending, (state, action) => {
      state.requestLoading = true;
      state.failedRequestingQuotes = false;
    });
    builder.addCase(requestQuotes.fulfilled, (state, action) => {
      state.requestLoading = false;
      state.failedRequestingQuotes = false;
      state.quoteRequest = action.payload;
    });
    builder.addCase(requestQuotes.rejected, (state, action) => {
      state.requestLoading = false;
      state.failedRequestingQuotes = true;
    });

    builder.addCase(serviceBuilding.pending, (state, action) => {
      state.requestLoading = true;
    });
    builder.addCase(serviceBuilding.fulfilled, (state, action) => {
      state.requestLoading = false;
    });
    builder.addCase(serviceBuilding.rejected, (state, action) => {
      state.requestLoading = false;
      state.requestFailed = true;
    });

    builder.addCase(ipAddress.fulfilled, (state, action) => {
      state.ipAddress = action.payload.ip;
    });
  },
});

const getQuotes = createAsyncThunk(
  "getQuotes",
  async (params, { rejectWithValue }) => {
    const { requestBody } = params;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: requestBody,
    };

    try {
      const response = await fetch(
        "https://validator.fiberfed.net/quote/get_addresses/",
        requestOptions
      );
      const data = await response.json();
      return data;
    } catch (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  }
);

const requestQuotes = createAsyncThunk(
  "requestQuotes",
  async (params, { rejectWithValue }) => {
    const { requestBody } = params;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: requestBody,
    };

    try {
      const response = await fetch(
        "https://validator.fiberfed.net/quote/send_quote/",
        requestOptions
      );
      const data = await response.json();
      return data;
    } catch (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  }
);

const serviceBuilding = createAsyncThunk(
  "serviceBuilding",
  async (params, { rejectWithValue }) => {
    const { payload, cb } = params;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(
        "https://validator3.fiberfed.net/api/building/service-building/",
        requestOptions
      );
      const data = await response.json();
      if (response.status === 202) {
        cb();
      } else {
        toast.error("Something went wrong, please try again", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      return data;
    } catch (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  }
);

const ipAddress = createAsyncThunk(
  "ip-address",
  async (_, { rejectWithValue }) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    try {
      const response = await fetch("https://ipapi.co/json/", requestOptions);
      const data = await response.json();

      if (response.status !== 200) {
        toast.error("Something went wrong, please try again", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      return data;
    } catch (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return rejectWithValue(error);
    }
  }
);

export { getQuotes, requestQuotes, serviceBuilding, ipAddress };

export default quotesSlice.reducer;
