function Loader({ text }) {
  return (
    <>
      <div className="py-32 flex items-center justify-center h-full">
        <div className="flex items-center justify-center">
          <div className="rect space-x-1">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
          <p className="ml-2 text-blue font-semibold">{text}</p>
        </div>
      </div>
    </>
  );
}

export default Loader;
