import { ReactComponent as Arrow } from "../../assets/gray-arrow.svg";

function LeftArrowButton() {
  return (
    <div className="hover:bg-orange transition duration-700 rounded-full p-4 lg:p-6 flex justify-center border border-gray transform rotate-180">
      <Arrow className="m-0 hoverarrow" />
    </div>
  );
}

export default LeftArrowButton;
