import { Router, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";

import Landing from "./components/Landing/Landing";
import ScrollToTop from "./helpers/ScrollToTop";
import Store from "./store";

import MainApp from "./components/MainApp";
import history from "./routes/history";
import MetaPixel from "./helpers/meta-pixel";
import React from "react";
import { Mixpanel } from "./helpers/mixpanel";
import { useDispatch, useSelector } from "react-redux";
import { ipAddress } from "./store/slices/quotesSlice";

function App() {
  const dispatch = useDispatch();
  const { ipAddress: address } = useSelector((state) => state.quotes);

  React.useEffect(() => {
    dispatch(ipAddress());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (address) {
      Mixpanel.identify(address);
    }
  }, [address]);

  return (
    <Store>
      <MetaPixel />
      <Router history={history}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/app" component={MainApp} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </Store>
  );
}

export default App;
