import { Link } from "react-router-dom";

import "./InAppFooter.scss";

import service from "../../assets/chooseservice.svg";
import getquote from "../../assets/getquote.svg";
import order from "../../assets/order.svg";
import location from "../../assets/location.svg";

function InAppFooter() {
  return (
    <div className="px-7 sm:px-24 lg:px-10 xl:px-32 pb-8 bg-fc">
      <div className="footer-options">
        <div className="footer-option">
          <img src={service} alt="service" />
          <div>
            <h2>Choose a Service</h2>
            <p>Tell us the service you would like to purchase</p>
          </div>
        </div>
        <div className="footer-option">
          <img src={location} alt="service" />
          <div>
            <h2>Input a Location</h2>
            <p>Tell us where you would like the services to be installed</p>
          </div>
        </div>
        <div className="footer-option">
          <img src={getquote} alt="service" />
          <div>
            <h2>Get a Quote</h2>
            <p>We send an quote to your email inbox within 24 hours</p>
          </div>
        </div>
        <div className="footer-option">
          <img src={order} alt="service" />
          <div>
            <h2>Place an Order</h2>
            <p>After getting our quote, you place on order via email</p>
          </div>
        </div>
      </div>
      <div className="border-b border-gray my-6 w-8/12"></div>
      <div className="text-xs text-lightergray flex flex-col sm:flex-row justify-between">
        <p>© 2020 Fiberfed Broadband Solutions, All Rights Reserved</p>
        <div className="mt-6 sm:mt-0">
          <Link to="/">Home </Link>/<a href="/"> Network </a>/
          <a href="/"> Services </a>/<a href="/"> Support</a>
        </div>
      </div>
    </div>
  );
}

export default InAppFooter;
