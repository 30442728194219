import LeftArrowButton from "../small-components/LeftArrowButton";

import grayheadphones from "../../assets/grayheadphones.svg";
import whitearrow from "../../assets/white-arrow.svg";
import tag from "../../assets/budgettag.svg";
import { Link, useHistory } from "react-router-dom";
import { useContext } from "react";
import { SearchContext } from "../../store";

function BudgetSelection() {
  let history = useHistory();

  const [searchMechanism, setSearchMechanism] = useContext(SearchContext);

  const budget = {
    below500: {
      min: 0,
      max: 500,
    },
    "500to1000": {
      min: 500,
      max: 1000,
    },
    "1000to1500": {
      min: 1000,
      max: 1500,
    },
    "1500to2000": {
      min: 1500,
      max: 2000,
    },
    above2000: {
      min: 2000,
      max: 10000,
    },
  };

  const getMinMax = (event) => {
    setSearchMechanism({
      ...searchMechanism,
      budget_min: budget[event.target.value].min,
      budget_max: budget[event.target.value].max,
    });
  };

  return (
    <div>
      <div className="lg:h-screen px-8 sm:px-16 py-10 xl:py-32">
        <div className="flex items-center justify-between">
          <button onClick={history.goBack}>
            <LeftArrowButton />
          </button>
          <a className="" href="tel:855-342-3737">
            <button className="lg:hidden cta2 border-gray text-lightgray bg-transparent">
              <img alt="arrow" src={grayheadphones} /> +1 855-342-3737
            </button>
          </a>
        </div>
        <div className="mt-10 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          <h1 className="mt-3 lg:mt-0 text-4xl font-medium order-2 lg:order-1">
            Next Step
          </h1>
          <span className="px-3 py-2 bg-fc text-lightgray text-sm rounded-full order-1 lg:order-2">
            Step 3 of 4
          </span>
        </div>
        <p className="mt-6 lg:mt-12 pb-4 border-b border-gray text-lightgray text-xs sm:text-sm">
          What’s your budget?
        </p>
        <div className="">
          <img alt="tag" src={tag} className="mt-16" />
          <select
            className="mt-5 w-full border-b border-orange py-4 text-sm text-lightgray bg-transparent focus:outline-none"
            onChange={getMinMax.bind(this)}
          >
            <option disabled selected>
              Select a budget
            </option>
            <option value="below500">Below $500</option>
            <option value="500to1000">$500 to $1,000</option>
            <option value="1000to1500">$1000 to $1,500</option>
            <option value="1500to2000">$1,500 to $2,000 </option>
            <option value="above2000">Above $2,000</option>
          </select>
          <div className="mt-10 flex justify-end">
            <Link to="/app/addresses">
              <button className="cta2 w-full sm:w-auto justify-center border-blue text-white bg-blue">
                <img alt="arrow" src={whitearrow} /> CONTINUE
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BudgetSelection;
