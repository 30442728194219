import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useContext, useState } from "react";
import { SearchContext } from "../../store";

import LeftArrowButton from "../small-components/LeftArrowButton";
import Autocomplete from "react-google-autocomplete";

import grayheadphones from "../../assets/grayheadphones.svg";
import whitearrow from "../../assets/white-arrow.svg";
import buildings from "../../assets/buildings.svg";
import info from "../../assets/info.svg";
import { Mixpanel } from "../../helpers/mixpanel";

function Addresses() {
  const history = useHistory();

  const [searchMechanism, setSearchMechanism] = useContext(SearchContext);

  const numberOfAddresses = {
    DIA: {
      addresses: 1,
      helptext: "Dedicated Internet Access requires only one location",
    },
    BGPDIA: {
      addresses: 1,
      helptext: "Dedicated Internet Access requires only one location",
    },
    BROADBAND: {
      addresses: 1,
      helptext: "Broadband requires only one location",
    },
    SDWAN: {
      addresses: 1,
      helptext: "SDWAN requires one or more locations",
    },
    MPLS: { addresses: 2, helptext: "MPLS requires two or more locations" },
  };

  const placeholderTexts = {
    "data-center": "Enter DataCenter Address",
    "business-name": "Enter Business Address",
    address: "Enter Building Address",
  };

  if (searchMechanism.service === "BROADBAND") {
    var placeholder = "Enter a Business Address";
  } else {
    placeholder = placeholderTexts[searchMechanism["building_type"]];
  }

  const [inputArray, setinputArray] = useState([
    ...Array(numberOfAddresses[searchMechanism["service"]]?.addresses).keys(),
  ]);

  const addAddress = (i) => {
    Mixpanel.track("CLICKED ADD ANOTHER BUILDING");

    let temp = inputArray.splice(0);
    temp.push(temp.length);
    setinputArray(temp);
  };

  const returnZip = (place) => {
    let zip;
    for (var i = 0; i < place.address_components.length; i++) {
      for (var j = 0; j < place.address_components[i].types.length; j++) {
        if (place.address_components[i].types[j] === "postal_code") {
          zip = place.address_components[i].long_name;
        }
      }
    }
    return zip || "0000000";
  };

  const returnCity = (place) => {
    let locality, sublocality;
    for (var i = 0; i < place.address_components.length; i++) {
      for (var j = 0; j < place.address_components[i].types.length; j++) {
        if (place.address_components[i].types[j] === "locality") {
          locality = place.address_components[i].short_name;
        }
        if (place.address_components[i].types[j] === "sublocality") {
          sublocality = place.address_components[i].short_name;
        }
      }
    }
    return locality || sublocality;
  };
  const returnState = (place) => {
    for (var i = 0; i < place.address_components.length; i++) {
      for (var j = 0; j < place.address_components[i].types.length; j++) {
        if (
          place.address_components[i].types[j] === "administrative_area_level_1"
        ) {
          return place.address_components[i].short_name;
        }
      }
    }
  };

  const setAddressData = (place, index) => {
    searchMechanism["addresses"][index] = {
      service:
        searchMechanism["recommendedservice"] === "SDWAN2" &&
        searchMechanism["mechanism"] === "recommendation"
          ? "BROADBAND_BROADBAND"
          : searchMechanism["recommendedservice"] === "SDWAN3" &&
            searchMechanism["mechanism"] === "recommendation"
          ? "DIA_BROADBAND"
          : searchMechanism["service"] === "SDWAN"
          ? "DIA_DIA"
          : searchMechanism["service"],
      ...searchMechanism["addresses"][index],
      address: place.formatted_address.split(",")[0],
      longitude: place.geometry.location.lng(),
      latitude: place.geometry.location.lat(),
      zip: returnZip(place),
      city: returnCity(place),
      state: returnState(place),
    };

    setSearchMechanism({
      ...searchMechanism,
      quote: "",
      addresses: searchMechanism["addresses"],
    });
  };

  const handleChange = (index, event) => {
    searchMechanism["addresses"][index] = {
      ...searchMechanism["addresses"][index],
      service: event.target.value,
    };
    setSearchMechanism({
      ...searchMechanism,
      addresses: searchMechanism["addresses"],
    });
  };

  const handleInput = () => {
    const input = document.querySelectorAll("#building-name");

    Mixpanel.track("CONTINUE TO GET QUOTE", {
      action: `${searchMechanism.addresses}`,
    });

    for (var i = 0; i < input.length; i++) {
      if (input[i].value === "") {
        var empty = true;
      }
    }

    if (empty) {
      toast.error("Please fill in all addresses", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      history.push("/app/get-quote");
    }
  };

  return (
    <div>
      <div className="lg:h-screen px-8 sm:px-16 py-10 xl:py-32 overflow-y-auto">
        <div className="flex items-center justify-between">
          <button onClick={history.goBack}>
            <LeftArrowButton />
          </button>
          <a href="tel:855-342-3737">
            <button className="lg:hidden cta2 border-gray text-lightgray bg-transparent">
              <img alt="arrow" src={grayheadphones} /> +1 855-342-3737
            </button>
          </a>
        </div>
        <div className="mt-10 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          <h1 className="mt-3 lg:mt-0 text-4xl font-medium order-2 lg:order-1 w-9/12">
            Enter your Building address without Suite/Apt
          </h1>
          <span className="px-3 py-2 bg-fc text-lightgray text-sm rounded-full order-1 lg:order-2">
            Step 3 of 4
          </span>
        </div>
        <p className="mt-6 lg:mt-12 pb-4 border-b border-gray text-lightgray text-xs sm:text-sm">
          Your data is safe with us, we do not sell your data to 3rd parties
        </p>
        <div className="">
          <div className="mt-10 flex items-center space-x-3 px-5 py-4 rounded-xl bg-f2">
            <img alt="tag" src={info} />
            <p className="text-blue text-xs sm:text-sm">
              {numberOfAddresses[searchMechanism["service"]].helptext}
            </p>
          </div>
          <div id="input-array">
            {inputArray.map((i) => (
              <div className="mt-12" key={i}>
                <div className="flex items-center justify-between w-32 px-3 py-2 bg-fc text-lightgray text-sm rounded-full">
                  <img alt="building" src={buildings} />
                  Address {i + 1}
                </div>
                <div className="flex items-end">
                  {searchMechanism.service === "SDWAN" &&
                  searchMechanism.mechanism !== "recommendation" ? (
                    <div className="flex w-3/12 sm:w-3/12">
                      <div className="w-10/12">
                        <select
                          className="inapp-input"
                          onChange={handleChange.bind(this, i)}
                          id=""
                        >
                          <option value="DIA,DIA">DIA & DIA</option>
                          <option value="BROADBAND,BROADBAND">
                            BROADBAND & BROADBAND
                          </option>
                          <option value="DIA,BROADBAND">DIA & BROADBAND</option>
                        </select>
                      </div>
                      <div className="w-2/12"></div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className={
                      searchMechanism.service === "SDWAN" &&
                      searchMechanism.mechanism !== "recommendation"
                        ? "w-8/12"
                        : "w-full"
                    }
                  >
                    <Autocomplete
                      id="building-name"
                      aria-required
                      autoComplete="new-password"
                      apiKey={"AIzaSyAGFRqlf2vLWd0bJ9q58MgtpZjAzLJghBI"}
                      options={{ types: [] }}
                      placeholder={
                        searchMechanism.mechanism === "location" ||
                        searchMechanism.service === "BROADBAND"
                          ? placeholder
                          : "Enter a Building Address"
                      }
                      className="inapp-input"
                      onPlaceSelected={(place) => {
                        Mixpanel.track(
                          `SELECTED AN ADDRESS ${
                            place.formatted_address.split(",")[0]
                          }`
                        );
                        setAddressData(place, i);
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-10 flex flex-col w-full lg:flex-row justify-between">
            <button
              onClick={addAddress}
              className={
                "flex items-center space-x-3 uppercase text-xs font-semibold text-lightgray " +
                (searchMechanism.service === "MPLS" ||
                searchMechanism.service === "BROADBAND" ||
                searchMechanism.service === "DIA" ||
                searchMechanism.service === "SDWAN" ||
                searchMechanism.service === "BGPDIA"
                  ? "block"
                  : "hidden")
              }
            >
              <span>Add another building +</span>
            </button>
            <button
              className="cta2 mt-7 lg:mt-0 w-full sm:w-auto justify-center border-blue text-white bg-blue"
              onClick={handleInput}
            >
              <img alt="arrow" src={whitearrow} /> CONTINUE
            </button>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </div>
  );
}

export default Addresses;
