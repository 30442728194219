import React, { useState } from "react";

export const SearchContext = React.createContext();

const initial = {
  addresses: [],
  mechanism: "",
  service: "",
  quote: "",
};

const Store = ({ children }) => {
  const [searchMechanism, setSearchMechanism] = useState(
    localStorage.getItem("searchMechanism")
      ? JSON.parse(localStorage.getItem("searchMechanism"))
      : initial
  );
  React.useEffect(() => {
    localStorage.setItem("searchMechanism", JSON.stringify(searchMechanism));
  }, [searchMechanism]);

  React.useEffect(() => {
    const searchMechanismData = JSON.parse(
      localStorage.getItem("searchMechanism")
    );

    if (searchMechanismData) {
      setSearchMechanism(searchMechanismData);
    }
  }, []);
  return (
    <div>
      <SearchContext.Provider value={[searchMechanism, setSearchMechanism]}>
        {children}
      </SearchContext.Provider>
    </div>
  );
};

export default Store;
